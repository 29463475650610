import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import { useTranslation } from 'react-i18next';
import LatestBlog from '../../Shared/LatestBlog';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';
import AllergyInfoTeam from './AllergyInfoTeam';

const AllergyInfo = () => {
    const { t } = useTranslation();
    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle={t('allergy_information')} />
            <AllergyInfoTeam />
            <LatestBlog />
            <Apps />
            <Footer />
        </>
    );
};

export default AllergyInfo;