// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationHI from './locales/hi.json';
import translationSP from './locales/sp.json';
import translationDE from './locales/de.json';

i18n.use(initReactI18next).init({
  resources: {
    'en': { translation: translationEN },
    'fr': { translation: translationFR },
    'hi': { translation: translationHI },
    'sp': { translation: translationSP },
    'de': { translation: translationDE }
  },
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // Not needed for React as it escapes by default
  },
});

// export { translationEN }; // Export the English translation for external use
export default i18n;

