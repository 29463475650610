import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import whiteLogo from '../../assets/images/white-logo.png'
import { useTranslation } from 'react-i18next';
const Footer = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
                <div className="container">
                    <div className="row padding-bottom">
                        <Fade fade delay={100}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-about">
                                    <div className="footer-logo sc-mb-25">
                                        <Link to="#"><img src={whiteLogo} alt="FooterLogo" /></Link>
                                    </div>
                                    <p className="footer-des">{t('footer_title')}</p>
                                    <ul className="about-icon">
                                        <li>
                                            <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/MEAL_RUSH" target="_blank" rel="noreferrer"><i className="ri-twitter-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/98013048/admin/feed/posts/" target="_blank" rel="noreferrer"><i className="ri-linkedin-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.pinterest.co.uk/MEAL_RUSH/?actingBusinessId=1083467760262082420" target="_blank" rel="noreferrer"><i className="ri-pinterest-fill"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-xs-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0 sc-md-mb-30 sc-sm-mb-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">{t('company')}</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/about#">{t('about_us')}</Link></li>
                                        <li><Link to="/privacy-policy#">{t('privacy_policy')}</Link></li>
                                        <li><Link to="/terms#">{t('terms_and_conditions')}</Link></li>
                                        <li><Link to="/contact#">{t('contact')}</Link></li>
                                        <li><Link to="/terms-of-use#">{t('terms_of_use')}</Link></li>
                                        <li><Link to="/allergy-info#">{t('allergy_information')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={300}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">{t('resources')}</h4>
                                    <ul className="footer-menu-list">
                                        {/* <li><Link to="/price#">Products</Link></li> */}
                                        <li><Link to="/services#">{t('service')}</Link></li>
                                        <li><Link to="/price#">{t('pricing')}</Link></li>
                                        <li><Link to="/faq#">{t('faq')}</Link></li>
                                        {/* <li><Link to="/contact#">Careers</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={400}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">{t('get_in_touch')}</h4>
                                    <ul className="footer-menu-list">
                                        <li><i className="ri-mail-fill"></i><a href="mailto:admin@meal-rush.com">{t('admin_email')}</a></li>
                                        <li><i className="ri-phone-fill"></i><a href="tel:00441254801824">{t('admin_phone1')}</a></li>
                                        <li><i className="ri-phone-fill"></i><a href="tel:00441254801824">{t('admin_phone2')}</a></li>
                                        <li className="footer-map"><i className="ri-map-pin-fill"></i><span>{t('admin_address')}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>&copy; {new Date().getFullYear()} <Link to="#"> {t('company_name')}, </Link>{t('copy_right_msg')}</p>
                                    <p>{t('company_number')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;