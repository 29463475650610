import React, { /*useState*/ } from 'react';
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

const PricePlan = () => {
    // let [priceToggle, setPriceToggle] = useState(true)
    const { t } = useTranslation();
    let priceToggle = true;
    return (
        <>
            <section className="sc-price-area gray-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25">{t('price_page_title')}</h2>
                        <span className="sub_title">{t('price_page_subtitle')}</span>
                    </div>
                    {/* <div className="pricing-selector">
                        <form >
                            <button type="button" className="pricing-monthly-btn">Monthly</button>
                            <input type="checkbox" name="pricing-selector" id="pricing-selector" onChange={() => setPriceToggle(!priceToggle)} />
                            <button type="button" className="pricing-yearly-btn">Yearly <span>(20% Off)</span></button>
                        </form>
                    </div> */}
                    <div className={`${priceToggle ? 'pricing-monthly' : 'pricing-monthly d-none'}`}>
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">{t('free')}</h4>
                                            <div className="price-active">{t('price_1')}<span className="month"> - {t('price_1_title1')}</span></div>
                                            <div className="price-active"><span className="month">{t('price_1_title2')}</span></div>
                                        </div>
                                        <div className="perfect-title">{t('price_1_title_3')}</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>{t('price_1_desc_msg_1')} </span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>{t('price_1_desc_msg_2')}</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>{t('price_1_desc_msg_3')}</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i><span><b>{t('price_1_desc_msg_4')}</b></span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i><span>{t('price_1_desc_msg_5')}</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>{t('price_1_desc_msg_6')}</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>{t("price_1_desc_msg_7")}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_8')} </li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_9')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_10')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_11')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_12')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_13')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_15')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_16')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_17')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_18')}</li>
                                                <li><i className="ri-checkbox-circle-fill  check"></i>{t('price_1_desc_msg_19')}</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>{t('price_1_btn')}</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30" >
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">{t('price_2')}</h4>
                                            <div className="price-active price">{t('price_2_amt')}<span className="month"> - {t('price_2_title1')}</span></div>
                                            <div className="price-active price"><span className="month">{t('price_2_title2')}</span></div>
                                        </div>
                                        <div className="perfect-title">{t('price_2_title3')}</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>{t('price_2_desc_msg_1')}</li>
                                                <li><i className="ri-checkbox-circle-fill"></i>{t('price_1_desc_msg_2')}</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span><b>{t('price_1_desc_msg_4')}</b></span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>{t('price_1_desc_msg_3')}</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>{t('price_1_desc_msg_5')}</span></li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_6')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_7')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_8')} </li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_9')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_10')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_11')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_12')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_13')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_15')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_16')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_17')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_18')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_19')}</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>{t('price_2_btn')}</span></Link>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">{t('price_2_title4')}</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg-4 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">{t('price_3')}</h4>
                                            <div className="price-active price">{t('price_3_amt')}<span className="month"> - {t('price_2_title1')}</span></div>
                                            <div className="price-active price"><span className="month">{t('price_3_title1')}</span></div>
                                        </div>
                                        <div className="perfect-title">{t('price_3_title2')}</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_3_desc_msg_1')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_3_desc_msg_2')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i><span><b>{t('price_3_desc_msg_3')}</b></span></li>
                                                <li><i className="ri-checkbox-circle-fill "></i><span>{t('price_3_desc_msg_4')}</span></li>
                                                <li><i className="ri-checkbox-circle-fill "></i><span>{t('price_1_desc_msg_5')}</span></li>
                                                {/* <li><i className="ri-checkbox-circle-fill "></i>Stripe Integration</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>DoJo Payments</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Roles / Permissions </li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Free Menu Setup</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Menu Versioning</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Asset Management</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Customer Management</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Employee Attendance</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Custom Discount Setup</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Loyalty Points Setup</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Reports & Analytics</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Next Day Payment</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>Free Training & Support</li> */}
                                                 <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_6')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_7')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_8')} </li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_9')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_10')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_11')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_12')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_13')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_15')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_16')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_17')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_18')}</li>
                                                <li><i className="ri-checkbox-circle-fill "></i>{t('price_1_desc_msg_19')}</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>{t('price_2_btn')}</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    
                    <div className={`${priceToggle ? 'pricing-yearly d-none' : 'pricing-yearly'}`}>
                        <div className="row">
                            <Fade bottom delay={100}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Free</h4>
                                            <div className="price-active">$25.00<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Restaurant/Take Away</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i><span>1 Users</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>SaaS Metrics</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Team Collaboration</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Upload Your Document</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Try It’s Free</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={200}>
                                <div className="col-lg-4 col-md-6 sc-md-mb-30">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Basic</h4>
                                            <div className="price-active price">$79.99<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Restaurant/Take Away</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>1 Users</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>SaaS Metrics</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Team Collaboration</span></li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Upload Your Document</li>
                                                <li><i className="ri-checkbox-circle-fill check"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Buy Now</span></Link>
                                        </div>
                                        <div className="price_sale">
                                            <span className="popular">popular</span>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                            <Fade bottom delay={300}>
                                <div className="col-lg-4 col-md-6">
                                    <div className="sc-price-box">
                                        <div className="price-item">
                                            <h4 className="pric-title">Pro</h4>
                                            <div className="price-active price">$99.99<span className="month"> - Year</span></div>
                                        </div>
                                        <div className="perfect-title">Perfect for Restaurant/Take Away</div>
                                        <div className="price-list">
                                            <ul className="list">
                                                <li><i className="ri-checkbox-circle-fill"></i>1 Users</li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>SaaS Metrics</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Team Collaboration</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i><span>Upload Your Document</span></li>
                                                <li><i className="ri-checkbox-circle-fill"></i>Export PDF</li>
                                            </ul>
                                        </div>
                                        <div className="sc-primary-btn">
                                            <Link className="price-btn" to="/contact#"><span>Buy Now</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PricePlan;