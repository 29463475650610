import React, { useState, useEffect } from 'react';
import aboutImg1 from '../../../assets/images/about/ab_img.png'
import aboutImg2 from '../../../assets/images/about/ab_img1.png'
import aboutImg3 from '../../../assets/images/about/ab_img2.png'
import { getWebsiteInfo } from '../../../services/Services';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const PrivacyPolicyTeam = () => {
    const [aboutUsContent, setAboutUsContent] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        getWebsiteInfo().then(response => {
            if (response.code === '1') {
                setAboutUsContent(response.data);
            }
            else {
                toast.error(response.message);
            }
        })
    }, []);

    return (
        <>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1 p-z-idex">
                                <div className="sc-heading-area sc-mb-105 sc-md-mb-60 text-center">
                                    <span className="sc-sub-title primary-color">{t('privacy_policy')}</span>
                                    {/* <h2 className="heading-title sc-mb-25">We are committed to supporting businesses of all types and sizes, empowering them to achieve their goals and serve their customers with passion and dedication.</h2> */}
                                    <div className="description sc-mb-20">
                                        <div dangerouslySetInnerHTML={{ __html: aboutUsContent.websiteinfoprivacypolicy }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row position-relative">
                            <div className="col-lg-4 p-z-idex">
                                <div className="about_team_Image text-center sc-md-mb-30">
                                    <img src={aboutImg1} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-4 p-z-idex">
                                <div className="about_team_Image text-center sc-md-mb-30">
                                    <img src={aboutImg2} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-4 p-z-idex">
                                <div className="about_team_Image text-center">
                                    <img src={aboutImg3} alt="About" />
                                </div>
                            </div>
                            <div className="sc-ab-circle purple"></div>
                            <div className="sc-ab-circle yelow"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicyTeam;