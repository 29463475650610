import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/feature/feature_img7.png'
import { useTranslation } from 'react-i18next';
const ServiceFeature = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Feature" />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">{t('what_we_do')}</span>
                                        <h2 className="heading-title sc-mb-25">{t('service_title')}</h2>
                                        <div className="description sc-mb-25">
                                            {t('service_description')}
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>{t('service_feature1')}</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>{t('service_feature2')}</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>{t('service_feature3')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;